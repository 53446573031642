import { useContext } from 'react';
import styled from 'styled-components';

import { AsurionDoodleSpinner } from '@soluto-private/mx-asurion-ui-react';

import { ExpertSDKActionsContext } from '@service-initiation/widget-verizon-data-access-expert-sdk';

const SDK_HEADER_PADDING = `58px`;

const ExpertSDKPanel = styled.div<{
  activeChat: boolean;
}>`
  position: absolute;
  pointer-events: ${({ activeChat }) => (activeChat ? 'auto' : 'none')};
  width: 100%;
  height: calc(100% - ${SDK_HEADER_PADDING});
  bottom: 0;
  left: 0;
  z-index: 1;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const ExpertChatPanel = () => {
  const { activeChat, mounting } = useContext(ExpertSDKActionsContext);

  return mounting ? (
    <SpinnerContainer>
      <AsurionDoodleSpinner />
    </SpinnerContainer>
  ) : (
    <ExpertSDKPanel activeChat={!!activeChat} id="remote-expert-container" />
  );
};
