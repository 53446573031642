import { useContext } from 'react';

import { colors } from '@soluto-private/mx-asurion-ui-react';

import {
  AuiToken,
  useApplyAuiTokenClass,
} from '@service-initiation/shared-utils-hooks';
import { Bumper } from '@service-initiation/widget-features-bumper';
import { ChatWindow } from '@service-initiation/widget-features-chat-window';
import { ExpertSDKActionsContext } from '@service-initiation/widget-verizon-data-access-expert-sdk';

import { ExpertChatPanel } from './ExpertChatPanel';
import { HeaderContent } from './HeaderContent';
import './app.css';
import {
  useExpertChatActionListener,
  useInteractionModelActionListener,
} from './hooks';

const theme = {
  colors: {
    primary: colors.black,
  },
};

export function App() {
  const { activeChat } = useContext(ExpertSDKActionsContext);

  useApplyAuiTokenClass(document.body, AuiToken.PartnerLight);
  useExpertChatActionListener();
  useInteractionModelActionListener();

  return (
    <Bumper theme={theme}>
      <Bumper.Header>
        <HeaderContent />
      </Bumper.Header>
      <Bumper.Body>
        {activeChat ? (
          <ExpertChatPanel />
        ) : (
          <Bumper.Panel>
            <ChatWindow />
          </Bumper.Panel>
        )}
      </Bumper.Body>
    </Bumper>
  );
}

export default App;
