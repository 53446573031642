import { useContext } from 'react';
import styled from 'styled-components';

import {
  Icon as AuiIcon,
  Text,
  colors,
} from '@soluto-private/mx-asurion-ui-react';

import {
  Actions,
  ExpertSDKActionsContext,
} from '@service-initiation/widget-verizon-data-access-expert-sdk';

const Icon = styled(AuiIcon)`
  cursor: pointer;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

const MiddleTextContainer = styled.div`
  width: 100%;
  text-align: start;
  cursor: pointer;
`;

export const ExpandedHeaderExpertSDKPanelContent = () => {
  const { actionsHandler } = useContext(ExpertSDKActionsContext);

  return (
    <HeaderRow>
      <Icon
        fill={colors.white}
        onClick={(e) => {
          e.stopPropagation();
          actionsHandler(Actions.CloseExpertSDK);
        }}
        size="large"
        src="arrow-left"
        title="asurion"
      />
      <MiddleTextContainer>
        <Text as="h1" size={2} weight="heavy">
          Chat with an expert
        </Text>
      </MiddleTextContainer>
      <Icon
        fill={colors.white}
        size="large"
        src="chevron-down"
        title="asurion"
      />
    </HeaderRow>
  );
};
