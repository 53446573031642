import styled from 'styled-components';

import { Icon, Text, colors } from '@soluto-private/mx-asurion-ui-react';

const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
`;

export const CollapsedHeaderContent = () => {
  return (
    <>
      <TextContainer>
        <Text as="h1" size={2} weight={'heavy'}>
          Verizon Tech Coach
        </Text>
        <Text size={1}>Get tech help now</Text>
      </TextContainer>
      <Icon
        fill={colors.white}
        size="large"
        src="chat-bubble-circle"
        title="asurion"
      />
      <Icon fill={colors.white} size="large" src="phone" title="asurion" />
    </>
  );
};
