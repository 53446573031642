import {
  type InteractionNode,
  WidgetComponentType,
} from '@service-initiation/widget-data-access-interaction-model';
import { VariantOptions } from '@service-initiation/widget-ui-chat-bubble';
import { Actions } from '@service-initiation/widget-verizon-data-access-expert-sdk';

import { verizonInfoConfig } from './verizonInfoConfig';

export const verizonModel: InteractionNode[] = [
  {
    children: undefined,
    content: "Our experts can help you unlock your device's full potential.",
    id: 1,
    messageVariant: VariantOptions.ROUND,
    type: WidgetComponentType.MESSAGE,
  },
  {
    children: undefined,
    content: 'How can we help today?',
    id: 2,
    messageVariant: VariantOptions.FLAT,
    type: WidgetComponentType.MESSAGE,
  },
  {
    children: [
      {
        content: 'Sounds good!',
        id: 7,
        messageVariant: VariantOptions.ROUND,
        type: WidgetComponentType.MESSAGE,
      },
      {
        content: 'What brand is your new phone?',
        id: 8,
        messageVariant: VariantOptions.FLAT,
        type: WidgetComponentType.MESSAGE,
      },
      {
        children: [
          {
            content: "Great! Let's get your tech questions answered.",
            id: 13,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content:
              'Verizon Mobile Protect subscribers get premium tech help anytime, anywhere.',
            id: 14,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content: 'Choose how you would like to get help.',
            id: 15,
            messageVariant: VariantOptions.FLAT,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content: `Call us at ${verizonInfoConfig.phoneNumber}`,
            dataAnalyticsId: 'CallUsCTA',
            href: `tel:${verizonInfoConfig.phoneNumber}`,
            iconSrc: 'phone',
            id: 16,
            type: WidgetComponentType.SERVICE,
          },
          {
            content: 'Call me',
            dataAnalyticsId: 'CallMeCTA',
            href: `${verizonInfoConfig.callMeLink}`,
            iconSrc: 'custom/headset',
            id: 17,
            type: WidgetComponentType.SERVICE,
          },
          {
            actions: Actions.LaunchExpertSDK,
            content: 'Chat with an expert',
            dataAnalyticsId: 'ExpertChatCTA',
            iconSrc: 'chat-bubble-circle',
            id: 18,
            type: WidgetComponentType.SERVICE,
          },
          {
            content: 'Follow a set up guide',
            dataAnalyticsId: 'SetupGuideCTA',
            href: `${verizonInfoConfig.selfHelpGuideLink}`,
            iconSrc: 'content/content-book-2',
            id: 19,
            type: WidgetComponentType.SERVICE,
          },
        ],
        content: 'Apple / iPhone',
        id: 9,
        type: WidgetComponentType.SERVICE,
      },
      {
        children: [
          {
            content: "Great! Let's get your tech questions answered.",
            id: 20,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content:
              'Verizon Mobile Protect Subscribers get premium tech help anytime, anywhere.',
            id: 21,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content: 'Choose how you would like to get help.',
            id: 22,
            messageVariant: VariantOptions.FLAT,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content: `Call us at ${verizonInfoConfig.phoneNumber}`,
            dataAnalyticsId: 'CallUsCTA',
            href: `tel:${verizonInfoConfig.phoneNumber}`,
            iconSrc: 'phone',
            id: 23,
            type: WidgetComponentType.SERVICE,
          },
          {
            content: 'Call me',
            dataAnalyticsId: 'CallMeCTA',
            href: `${verizonInfoConfig.callMeLink}`,
            iconSrc: 'custom/headset',
            id: 24,
            type: WidgetComponentType.SERVICE,
          },
          {
            actions: Actions.LaunchExpertSDK,
            content: 'Chat with an expert',
            dataAnalyticsId: 'ExpertChatCTA',
            iconSrc: 'chat-bubble-circle',
            id: 25,
            type: WidgetComponentType.SERVICE,
          },
          {
            content: 'Follow a set up guide',
            dataAnalyticsId: 'SetupGuideCTA',
            href: `${verizonInfoConfig.selfHelpGuideLink}`,
            iconSrc: 'content/content-book-2',
            id: 26,
            type: WidgetComponentType.SERVICE,
          },
        ],
        content: 'Samsung / Galaxy',
        id: 10,
        type: WidgetComponentType.SERVICE,
      },
      {
        children: [
          {
            content: "Great! Let's get your tech questions answered.",
            id: 27,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content:
              'Verizon Mobile Protect Subscribers get premium tech help anytime, anywhere.',
            id: 28,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content: 'Choose how you would like to get help.',
            id: 29,
            messageVariant: VariantOptions.FLAT,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content: `Call us at ${verizonInfoConfig.phoneNumber}`,
            dataAnalyticsId: 'CallUsCTA',
            href: `tel:${verizonInfoConfig.phoneNumber}`,
            iconSrc: 'phone',
            id: 30,
            type: WidgetComponentType.SERVICE,
          },
          {
            content: 'Call me',
            dataAnalyticsId: 'CallMeCTA',
            href: `${verizonInfoConfig.callMeLink}`,
            iconSrc: 'custom/headset',
            id: 31,
            type: WidgetComponentType.SERVICE,
          },
          {
            actions: Actions.LaunchExpertSDK,
            content: 'Chat with an expert',
            dataAnalyticsId: 'ExpertChatCTA',
            iconSrc: 'chat-bubble-circle',
            id: 32,
            type: WidgetComponentType.SERVICE,
          },
          {
            content: 'Follow a set up guide',
            dataAnalyticsId: 'SetupGuideCTA',
            href: `${verizonInfoConfig.selfHelpGuideLink}`,
            iconSrc: 'content/content-book-2',
            id: 33,
            type: WidgetComponentType.SERVICE,
          },
        ],
        content: 'Google / Pixel',
        id: 11,
        type: WidgetComponentType.SERVICE,
      },
      {
        children: [
          {
            content: "Great! Let's get your tech questions answered.",
            id: 34,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content:
              'Verizon Mobile Protect Subscribers get premium tech help anytime, anywhere.',
            id: 35,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content: 'Choose how you would like to get help.',
            id: 36,
            messageVariant: VariantOptions.FLAT,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content: `Call us at ${verizonInfoConfig.phoneNumber}`,
            dataAnalyticsId: 'CallUsCTA',
            href: `tel:${verizonInfoConfig.phoneNumber}`,
            iconSrc: 'phone',
            id: 37,
            type: WidgetComponentType.SERVICE,
          },
          {
            content: 'Call me',
            dataAnalyticsId: 'CallMeCTA',
            href: `${verizonInfoConfig.callMeLink}`,
            iconSrc: 'custom/headset',
            id: 38,
            type: WidgetComponentType.SERVICE,
          },
          {
            actions: Actions.LaunchExpertSDK,
            content: 'Chat with an expert',
            dataAnalyticsId: 'ExpertChatCTA',
            iconSrc: 'chat-bubble-circle',
            id: 39,
            type: WidgetComponentType.SERVICE,
          },
          {
            content: 'Follow a set up guide',
            dataAnalyticsId: 'SetupGuideCTA',
            href: `${verizonInfoConfig.selfHelpGuideLink}`,
            iconSrc: 'content/content-book-2',
            id: 40,
            type: WidgetComponentType.SERVICE,
          },
        ],
        content: 'Something else',
        id: 12,
        type: WidgetComponentType.SERVICE,
      },
    ],
    content: 'Activate my new phone',
    iconSrc: 'controls/power-button-1',
    id: 3,
    type: WidgetComponentType.SERVICE,
  },
  {
    children: [
      {
        content: 'Sounds good!',
        id: 7,
        messageVariant: VariantOptions.ROUND,
        type: WidgetComponentType.MESSAGE,
      },
      {
        content: 'What brand is your new phone?',
        id: 8,
        messageVariant: VariantOptions.FLAT,
        type: WidgetComponentType.MESSAGE,
      },
      {
        children: [
          {
            content: "Great! Let's get your tech questions answered.",
            id: 13,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content:
              'Verizon Mobile Protect Subscribers get premium tech help anytime, anywhere.',
            id: 14,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content: 'Choose how you would like to get help.',
            id: 15,
            messageVariant: VariantOptions.FLAT,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content: `Call us at ${verizonInfoConfig.phoneNumber}`,
            dataAnalyticsId: 'CallUsCTA',
            href: `tel:${verizonInfoConfig.phoneNumber}`,
            iconSrc: 'phone',
            id: 16,
            type: WidgetComponentType.SERVICE,
          },
          {
            content: 'Call me',
            dataAnalyticsId: 'CallMeCTA',
            href: `${verizonInfoConfig.callMeLink}`,
            iconSrc: 'custom/headset',
            id: 17,
            type: WidgetComponentType.SERVICE,
          },
          {
            actions: Actions.LaunchExpertSDK,
            content: 'Chat with an expert',
            dataAnalyticsId: 'ExpertChatCTA',
            iconSrc: 'chat-bubble-circle',
            id: 18,
            type: WidgetComponentType.SERVICE,
          },
          {
            content: 'Follow a set up guide',
            dataAnalyticsId: 'SetupGuideCTA',
            href: `${verizonInfoConfig.selfHelpGuideLink}`,
            iconSrc: 'content/content-book-2',
            id: 19,
            type: WidgetComponentType.SERVICE,
          },
        ],
        content: 'Apple / iPhone',
        id: 9,
        type: WidgetComponentType.SERVICE,
      },
      {
        children: [
          {
            content: "Great! Let's get your tech questions answered.",
            id: 20,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content:
              'Verizon Mobile Protect Subscribers get premium tech help anytime, anywhere.',
            id: 21,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content: 'Choose how you would like to get help.',
            id: 22,
            messageVariant: VariantOptions.FLAT,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content: `Call us at ${verizonInfoConfig.phoneNumber}`,
            dataAnalyticsId: 'CallUsCTA',
            href: `tel:${verizonInfoConfig.phoneNumber}`,
            iconSrc: 'phone',
            id: 23,
            type: WidgetComponentType.SERVICE,
          },
          {
            content: 'Call me',
            dataAnalyticsId: 'CallMeCTA',
            href: `${verizonInfoConfig.callMeLink}`,
            iconSrc: 'custom/headset',
            id: 24,
            type: WidgetComponentType.SERVICE,
          },
          {
            actions: Actions.LaunchExpertSDK,
            content: 'Chat with an expert',
            dataAnalyticsId: 'ExpertChatCTA',
            iconSrc: 'chat-bubble-circle',
            id: 25,
            type: WidgetComponentType.SERVICE,
          },
          {
            content: 'Follow a set up guide',
            dataAnalyticsId: 'SetupGuideCTA',
            href: `${verizonInfoConfig.selfHelpGuideLink}`,
            iconSrc: 'content/content-book-2',
            id: 26,
            type: WidgetComponentType.SERVICE,
          },
        ],
        content: 'Samsung / Galaxy',
        id: 10,
        type: WidgetComponentType.SERVICE,
      },
      {
        children: [
          {
            content: "Great! Let's get your tech questions answered.",
            id: 27,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content:
              'Verizon Mobile Protect Subscribers get premium tech help anytime, anywhere.',
            id: 28,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content: 'Choose how you would like to get help.',
            id: 29,
            messageVariant: VariantOptions.FLAT,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content: `Call us at ${verizonInfoConfig.phoneNumber}`,
            dataAnalyticsId: 'CallUsCTA',
            href: `tel:${verizonInfoConfig.phoneNumber}`,
            iconSrc: 'phone',
            id: 30,
            type: WidgetComponentType.SERVICE,
          },
          {
            content: 'Call me',
            dataAnalyticsId: 'CallMeCTA',
            href: `${verizonInfoConfig.callMeLink}`,
            iconSrc: 'custom/headset',
            id: 31,
            type: WidgetComponentType.SERVICE,
          },
          {
            actions: Actions.LaunchExpertSDK,
            content: 'Chat with an expert',
            dataAnalyticsId: 'ExpertChatCTA',
            iconSrc: 'chat-bubble-circle',
            id: 32,
            type: WidgetComponentType.SERVICE,
          },
          {
            content: 'Follow a set up guide',
            dataAnalyticsId: 'SetupGuideCTA',
            href: `${verizonInfoConfig.selfHelpGuideLink}`,
            iconSrc: 'content/content-book-2',
            id: 33,
            type: WidgetComponentType.SERVICE,
          },
        ],
        content: 'Google / Pixel',
        id: 11,
        type: WidgetComponentType.SERVICE,
      },
      {
        children: [
          {
            content: "Great! Let's get your tech questions answered.",
            id: 34,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content:
              'Verizon Mobile Protect Subscribers get premium tech help anytime, anywhere.',
            id: 35,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content: 'Choose how you would like to get help.',
            id: 36,
            messageVariant: VariantOptions.FLAT,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content: `Call us at ${verizonInfoConfig.phoneNumber}`,
            dataAnalyticsId: 'CallUsCTA',
            href: `tel:${verizonInfoConfig.phoneNumber}`,
            iconSrc: 'phone',
            id: 37,
            type: WidgetComponentType.SERVICE,
          },
          {
            content: 'Call me',
            dataAnalyticsId: 'CallMeCTA',
            href: `${verizonInfoConfig.callMeLink}`,
            iconSrc: 'custom/headset',
            id: 38,
            type: WidgetComponentType.SERVICE,
          },
          {
            actions: Actions.LaunchExpertSDK,
            content: 'Chat with an expert',
            dataAnalyticsId: 'ExpertChatCTA',
            iconSrc: 'chat-bubble-circle',
            id: 39,
            type: WidgetComponentType.SERVICE,
          },
          {
            content: 'Follow a set up guide',
            dataAnalyticsId: 'SetupGuideCTA',
            href: `${verizonInfoConfig.selfHelpGuideLink}`,
            iconSrc: 'content/content-book-2',
            id: 40,
            type: WidgetComponentType.SERVICE,
          },
        ],
        content: 'Something else',
        id: 12,
        type: WidgetComponentType.SERVICE,
      },
    ],
    content: 'Learn about my phone',
    iconSrc: 'locations/compass-3',
    id: 4,
    type: WidgetComponentType.SERVICE,
  },
  {
    children: [
      {
        content: 'Sounds good!',
        id: 7,
        messageVariant: VariantOptions.ROUND,
        type: WidgetComponentType.MESSAGE,
      },
      {
        content: 'What brand is your new phone?',
        id: 8,
        messageVariant: VariantOptions.FLAT,
        type: WidgetComponentType.MESSAGE,
      },
      {
        children: [
          {
            content: "Great! Let's get your tech questions answered.",
            id: 13,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content:
              'Verizon Mobile Protect Subscribers get premium tech help anytime, anywhere.',
            id: 14,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content: 'Choose how you would like to get help.',
            id: 15,
            messageVariant: VariantOptions.FLAT,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content: `Call us at ${verizonInfoConfig.phoneNumber}`,
            dataAnalyticsId: 'CallUsCTA',
            href: `tel:${verizonInfoConfig.phoneNumber}`,
            iconSrc: 'phone',
            id: 16,
            type: WidgetComponentType.SERVICE,
          },
          {
            content: 'Call me',
            dataAnalyticsId: 'CallMeCTA',
            href: `${verizonInfoConfig.callMeLink}`,
            iconSrc: 'custom/headset',
            id: 17,
            type: WidgetComponentType.SERVICE,
          },
          {
            actions: Actions.LaunchExpertSDK,
            content: 'Chat with an expert',
            dataAnalyticsId: 'ExpertChatCTA',
            iconSrc: 'chat-bubble-circle',
            id: 18,
            type: WidgetComponentType.SERVICE,
          },
          {
            content: 'Follow a set up guide',
            dataAnalyticsId: 'SetupGuideCTA',
            href: `${verizonInfoConfig.selfHelpGuideLink}`,
            iconSrc: 'content/content-book-2',
            id: 19,
            type: WidgetComponentType.SERVICE,
          },
        ],
        content: 'Apple / iPhone',
        id: 9,
        type: WidgetComponentType.SERVICE,
      },
      {
        children: [
          {
            content: "Great! Let's get your tech questions answered.",
            id: 20,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content:
              'Verizon Mobile Protect Subscribers get premium tech help anytime, anywhere.',
            id: 21,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content: 'Choose how you would like to get help.',
            id: 22,
            messageVariant: VariantOptions.FLAT,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content: `Call us at ${verizonInfoConfig.phoneNumber}`,
            dataAnalyticsId: 'CallUsCTA',
            href: `tel:${verizonInfoConfig.phoneNumber}`,
            iconSrc: 'phone',
            id: 23,
            type: WidgetComponentType.SERVICE,
          },
          {
            content: 'Call me',
            dataAnalyticsId: 'CallMeCTA',
            href: `${verizonInfoConfig.callMeLink}`,
            iconSrc: 'custom/headset',
            id: 24,
            type: WidgetComponentType.SERVICE,
          },
          {
            actions: Actions.LaunchExpertSDK,
            content: 'Chat with an expert',
            dataAnalyticsId: 'ExpertChatCTA',
            iconSrc: 'chat-bubble-circle',
            id: 25,
            type: WidgetComponentType.SERVICE,
          },
          {
            content: 'Follow a set up guide',
            dataAnalyticsId: 'SetupGuideCTA',
            href: `${verizonInfoConfig.selfHelpGuideLink}`,
            iconSrc: 'content/content-book-2',
            id: 26,
            type: WidgetComponentType.SERVICE,
          },
        ],
        content: 'Samsung / Galaxy',
        id: 10,
        type: WidgetComponentType.SERVICE,
      },
      {
        children: [
          {
            content: "Great! Let's get your tech questions answered.",
            id: 27,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content:
              'Verizon Mobile Protect Subscribers get premium tech help anytime, anywhere.',
            id: 28,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content: 'Choose how you would like to get help.',
            id: 29,
            messageVariant: VariantOptions.FLAT,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content: `Call us at ${verizonInfoConfig.phoneNumber}`,
            dataAnalyticsId: 'CallUsCTA',
            href: `tel:${verizonInfoConfig.phoneNumber}`,
            iconSrc: 'phone',
            id: 30,
            type: WidgetComponentType.SERVICE,
          },
          {
            content: 'Call me',
            dataAnalyticsId: 'CallMeCTA',
            href: `${verizonInfoConfig.callMeLink}`,
            iconSrc: 'custom/headset',
            id: 31,
            type: WidgetComponentType.SERVICE,
          },
          {
            actions: Actions.LaunchExpertSDK,
            content: 'Chat with an expert',
            dataAnalyticsId: 'ExpertChatCTA',
            iconSrc: 'chat-bubble-circle',
            id: 32,
            type: WidgetComponentType.SERVICE,
          },
          {
            content: 'Follow a set up guide',
            dataAnalyticsId: 'SetupGuideCTA',
            href: `${verizonInfoConfig.selfHelpGuideLink}`,
            iconSrc: 'content/content-book-2',
            id: 33,
            type: WidgetComponentType.SERVICE,
          },
        ],
        content: 'Google / Pixel',
        id: 11,
        type: WidgetComponentType.SERVICE,
      },
      {
        children: [
          {
            content: "Great! Let's get your tech questions answered.",
            id: 34,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content:
              'Verizon Mobile Protect Subscribers get premium tech help anytime, anywhere.',
            id: 35,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content: 'Choose how you would like to get help.',
            id: 36,
            messageVariant: VariantOptions.FLAT,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content: `Call us at ${verizonInfoConfig.phoneNumber}`,
            dataAnalyticsId: 'CallUsCTA',
            href: `tel:${verizonInfoConfig.phoneNumber}`,
            iconSrc: 'phone',
            id: 37,
            type: WidgetComponentType.SERVICE,
          },
          {
            content: 'Call me',
            dataAnalyticsId: 'CallMeCTA',
            href: `${verizonInfoConfig.callMeLink}`,
            iconSrc: 'custom/headset',
            id: 38,
            type: WidgetComponentType.SERVICE,
          },
          {
            actions: Actions.LaunchExpertSDK,
            content: 'Chat with an expert',
            dataAnalyticsId: 'ExpertChatCTA',
            iconSrc: 'chat-bubble-circle',
            id: 39,
            type: WidgetComponentType.SERVICE,
          },
          {
            content: 'Follow a set up guide',
            dataAnalyticsId: 'SetupGuideCTA',
            href: `${verizonInfoConfig.selfHelpGuideLink}`,
            iconSrc: 'content/content-book-2',
            id: 40,
            type: WidgetComponentType.SERVICE,
          },
        ],
        content: 'Something else',
        id: 12,
        type: WidgetComponentType.SERVICE,
      },
    ],
    content: 'Back up my data',
    iconSrc: 'weather/cloudy',
    id: 5,
    type: WidgetComponentType.SERVICE,
  },
  {
    children: [
      {
        content: 'Sounds good!',
        id: 7,
        messageVariant: VariantOptions.ROUND,
        type: WidgetComponentType.MESSAGE,
      },
      {
        content: 'What brand is your new phone?',
        id: 8,
        messageVariant: VariantOptions.FLAT,
        type: WidgetComponentType.MESSAGE,
      },
      {
        children: [
          {
            content: "Great! Let's get your tech questions answered.",
            id: 13,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content:
              'Verizon Mobile Protect Subscribers get premium tech help anytime, anywhere.',
            id: 14,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content: 'Choose how you would like to get help.',
            id: 15,
            messageVariant: VariantOptions.FLAT,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content: `Call us at ${verizonInfoConfig.phoneNumber}`,
            dataAnalyticsId: 'CallUsCTA',
            href: `tel:${verizonInfoConfig.phoneNumber}`,
            iconSrc: 'phone',
            id: 16,
            type: WidgetComponentType.SERVICE,
          },
          {
            content: 'Call me',
            dataAnalyticsId: 'CallMeCTA',
            href: `${verizonInfoConfig.callMeLink}`,
            iconSrc: 'custom/headset',
            id: 17,
            type: WidgetComponentType.SERVICE,
          },
          {
            actions: Actions.LaunchExpertSDK,
            content: 'Chat with an expert',
            dataAnalyticsId: 'ExpertChatCTA',
            iconSrc: 'chat-bubble-circle',
            id: 18,
            type: WidgetComponentType.SERVICE,
          },
          {
            content: 'Follow a set up guide',
            dataAnalyticsId: 'SetupGuideCTA',
            href: `${verizonInfoConfig.selfHelpGuideLink}`,
            iconSrc: 'content/content-book-2',
            id: 19,
            type: WidgetComponentType.SERVICE,
          },
        ],
        content: 'Apple / iPhone',
        id: 9,
        type: WidgetComponentType.SERVICE,
      },
      {
        children: [
          {
            content: "Great! Let's get your tech questions answered.",
            id: 20,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content:
              'Verizon Mobile Protect Subscribers get premium tech help anytime, anywhere.',
            id: 21,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content: 'Choose how you would like to get help.',
            id: 22,
            messageVariant: VariantOptions.FLAT,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content: `Call us at ${verizonInfoConfig.phoneNumber}`,
            dataAnalyticsId: 'CallUsCTA',
            href: `tel:${verizonInfoConfig.phoneNumber}`,
            iconSrc: 'phone',
            id: 23,
            type: WidgetComponentType.SERVICE,
          },
          {
            content: 'Call me',
            dataAnalyticsId: 'CallMeCTA',
            href: `${verizonInfoConfig.callMeLink}`,
            iconSrc: 'custom/headset',
            id: 24,
            type: WidgetComponentType.SERVICE,
          },
          {
            actions: Actions.LaunchExpertSDK,
            content: 'Chat with an expert',
            dataAnalyticsId: 'ExpertChatCTA',
            iconSrc: 'chat-bubble-circle',
            id: 25,
            type: WidgetComponentType.SERVICE,
          },
          {
            content: 'Follow a set up guide',
            dataAnalyticsId: 'SetupGuideCTA',
            href: `${verizonInfoConfig.selfHelpGuideLink}`,
            iconSrc: 'content/content-book-2',
            id: 26,
            type: WidgetComponentType.SERVICE,
          },
        ],
        content: 'Samsung / Galaxy',
        id: 10,
        type: WidgetComponentType.SERVICE,
      },
      {
        children: [
          {
            content: "Great! Let's get your tech questions answered.",
            id: 27,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content:
              'Verizon Mobile Protect Subscribers get premium tech help anytime, anywhere.',
            id: 28,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content: 'Choose how you would like to get help.',
            id: 29,
            messageVariant: VariantOptions.FLAT,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content: `Call us at ${verizonInfoConfig.phoneNumber}`,
            dataAnalyticsId: 'CallUsCTA',
            href: `tel:${verizonInfoConfig.phoneNumber}`,
            iconSrc: 'phone',
            id: 30,
            type: WidgetComponentType.SERVICE,
          },
          {
            content: 'Call me',
            dataAnalyticsId: 'CallMeCTA',
            href: `${verizonInfoConfig.callMeLink}`,
            iconSrc: 'custom/headset',
            id: 31,
            type: WidgetComponentType.SERVICE,
          },
          {
            actions: Actions.LaunchExpertSDK,
            content: 'Chat with an expert',
            dataAnalyticsId: 'ExpertChatCTA',
            iconSrc: 'chat-bubble-circle',
            id: 32,
            type: WidgetComponentType.SERVICE,
          },
          {
            content: 'Follow a set up guide',
            dataAnalyticsId: 'SetupGuideCTA',
            href: `${verizonInfoConfig.selfHelpGuideLink}`,
            iconSrc: 'content/content-book-2',
            id: 33,
            type: WidgetComponentType.SERVICE,
          },
        ],
        content: 'Google / Pixel',
        id: 11,
        type: WidgetComponentType.SERVICE,
      },
      {
        children: [
          {
            content: "Great! Let's get your tech questions answered.",
            id: 34,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content:
              'Verizon Mobile Protect Subscribers get premium tech help anytime, anywhere.',
            id: 35,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content: 'Choose how you would like to get help.',
            id: 36,
            messageVariant: VariantOptions.FLAT,
            type: WidgetComponentType.MESSAGE,
          },
          {
            content: `Call us at ${verizonInfoConfig.phoneNumber}`,
            dataAnalyticsId: 'CallUsCTA',
            href: `tel:${verizonInfoConfig.phoneNumber}`,
            iconSrc: 'phone',
            id: 37,
            type: WidgetComponentType.SERVICE,
          },
          {
            content: 'Call me',
            dataAnalyticsId: 'CallMeCTA',
            href: `${verizonInfoConfig.callMeLink}`,
            iconSrc: 'custom/headset',
            id: 38,
            type: WidgetComponentType.SERVICE,
          },
          {
            actions: Actions.LaunchExpertSDK,
            content: 'Chat with an expert',
            dataAnalyticsId: 'ExpertChatCTA',
            iconSrc: 'chat-bubble-circle',
            id: 39,
            type: WidgetComponentType.SERVICE,
          },
          {
            content: 'Follow a set up guide',
            dataAnalyticsId: 'SetupGuideCTA',
            href: `${verizonInfoConfig.selfHelpGuideLink}`,
            iconSrc: 'content/content-book-2',
            id: 40,
            type: WidgetComponentType.SERVICE,
          },
        ],
        content: 'Something else',
        id: 12,
        type: WidgetComponentType.SERVICE,
      },
    ],
    content: 'Transfer apps and content',
    iconSrc: 'data-transfer/data-transfer-4',
    id: 6,
    type: WidgetComponentType.SERVICE,
  },
];
