import { useContext } from 'react';

import { useBumperContext } from '@service-initiation/widget-features-bumper';
import { ExpertSDKActionsContext } from '@service-initiation/widget-verizon-data-access-expert-sdk';

import { CollapsedHeaderContent } from './CollapsedHeaderContent';
import { ExpandedHeaderContent } from './ExpandedHeaderContent';
import { ExpandedHeaderExpertSDKPanelContent } from './ExpandedHeaderExpertSDKPanelContent';

export const HeaderContent = () => {
  const { activeChat } = useContext(ExpertSDKActionsContext);
  const { expanded } = useBumperContext();

  return activeChat && expanded ? (
    <ExpandedHeaderExpertSDKPanelContent />
  ) : expanded ? (
    <ExpandedHeaderContent />
  ) : (
    <CollapsedHeaderContent />
  );
};
