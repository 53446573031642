import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import { GlobalStyles } from '@soluto-private/mx-asurion-ui-react';

import { GlobalVendorScrollbarResetRule } from '@service-initiation/shared-ui-scrollbar-reset';
import { WidgetAnalyticsProvider } from '@service-initiation/widget-data-access-analytics';
import {
  InteractionModelContext,
  InteractionModelProvider,
} from '@service-initiation/widget-data-access-interaction-model';
import { ExpertSDKActionsProvider } from '@service-initiation/widget-verizon-data-access-expert-sdk';

import { App } from './app';
import { verizonModel } from './initializations';

declare global {
  interface Window {
    _fs_run_in_iframe: boolean;
  }
}

window['_fs_run_in_iframe'] = true;

ReactDOM.render(
  <StrictMode>
    <GlobalStyles />
    <GlobalVendorScrollbarResetRule />
    <InteractionModelProvider model={verizonModel}>
      <InteractionModelContext.Consumer>
        {({ contentSnippets }) => (
          <ExpertSDKActionsProvider messageContent={contentSnippets}>
            <WidgetAnalyticsProvider>
              <App />
            </WidgetAnalyticsProvider>
          </ExpertSDKActionsProvider>
        )}
      </InteractionModelContext.Consumer>
    </InteractionModelProvider>
  </StrictMode>,
  document.getElementById('root')
);
