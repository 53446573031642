import { useCallback, useContext, useEffect } from 'react';

import { bumperToHostWindowConnection } from '@service-initiation/widget-clients-bumper-to-host-window-connection';
import { InteractionModelContext } from '@service-initiation/widget-data-access-interaction-model';
import { type InitiateInteractionModelChatMessagePayload } from '@service-initiation/widget-loader-clients-bumper-widget-window-connection';

export const useInteractionModelActionListener = () => {
  const { selectNodeById } = useContext(InteractionModelContext);

  const handleInitiateChat = useCallback(
    (payload?: InitiateInteractionModelChatMessagePayload) => {
      if (payload?.nodeId) {
        selectNodeById(payload?.nodeId);
      }
    },
    [selectNodeById]
  );

  useEffect(() => {
    bumperToHostWindowConnection.addListener(
      'interaction-model-initiate-chat',
      handleInitiateChat
    );

    return () => {
      bumperToHostWindowConnection.removeListener(
        'interaction-model-initiate-chat',
        handleInitiateChat
      );
    };
  }, [handleInitiateChat]);
};
