import { useCallback, useContext, useEffect } from 'react';

import { bumperToHostWindowConnection } from '@service-initiation/widget-clients-bumper-to-host-window-connection';
import {
  Actions,
  ExpertSDKActionsContext,
} from '@service-initiation/widget-verizon-data-access-expert-sdk';

export const useExpertChatActionListener = () => {
  const { actionsHandler } = useContext(ExpertSDKActionsContext);

  const handleInitiateChat = useCallback(() => {
    actionsHandler(Actions.LaunchExpertSDK);
  }, [actionsHandler]);

  useEffect(() => {
    bumperToHostWindowConnection.addListener(
      'expert-initiate-chat',
      handleInitiateChat
    );

    return () => {
      bumperToHostWindowConnection.removeListener(
        'expert-initiate-chat',
        handleInitiateChat
      );
    };
  }, [handleInitiateChat]);
};
